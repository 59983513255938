import dayjs from "dayjs";
import axiosService from "./axiosService";
import entityService from "./entityService";
import orderService from "./orderService";
import { translateX } from "../utils/helpers";
import asaasService from "./asaasService";
import {
  API_RECEIVABLES_URL,
  BANK_ACCOUNT_CODE,
  BANK_AGENCY_CODE,
  BANK_CODE,
} from "../settings/constant";
import appService from "./appService";

const receivableService = {};

receivableService.generateBillFromOrder = async (
  orderId,
  buyerId,
  billingType = "whatever",
  dueDate = dayjs().endOf("day"),
) => {
  const order = await orderService.getOrderById(orderId);
  const buyer = await entityService.getEntityById(buyerId);

  let customer = entityService.decorateEntity({ ...buyer });
  customer = await entityService.upsertEntity(customer?._id, customer);

  const destination = `${translateX(order?.destiny)} (${dayjs(
    order?.departure,
  ).format("L")} - ${dayjs(order?.arrival).format("L")}) | ${
    order?.travellers?.length || 0
  } ${order?.travellers?.length === 1 ? "Viajante" : "Viajantes"}`;
  const description = `Pedido Nº ${order?._metadata?.counter} | ${order?.__operator?.name} | ${order?.__plan?.name} | ${destination}`;

  const total = order?.totalInOrderDate;

  const body = {
    billingType,

    value: total?.totalToPay,
    valueCurrency: "brl",
    dueDate,

    bankCode: BANK_CODE,
    agencyCode: BANK_AGENCY_CODE,
    accountCode: BANK_ACCOUNT_CODE,

    description,
    installment: 1,
    installmentTotal: total?.paymentInstallment || 1,

    // FINE AND INTEREST
    fineValue: 0,
    finePercentage: 0,
    fineType: "percentage",

    interestValue: 0,
    interestPercentage: 0,
    interestType: "percentage",

    discountValue: 0,
    discountPercentage: 0,
    discountType: "percentage",

    discountExpireDate: null,

    // CUSTOMER
    entityId: customer._id,

    orderId,
  };

  // POST RECEIVABLE
  let axiosOptions = { url: API_RECEIVABLES_URL, data: body };
  let [doc] = await axiosService.post(axiosOptions);

  // GENERATE ASAAS PAYMENT LINK
  await asaasService.generatePaymentUrl(doc, customer);

  return doc;
};

receivableService.cancelBill = async (receivableId, quiet) => {
  return new Promise((resolve, reject) => {
    const axiosOptions = {
      _id: receivableId,
      url: `${API_RECEIVABLES_URL}/cancel-by-id`,
      data: {},
    };

    if (quiet) return $done();

    const options = {
      Content: "cancel_bill_description",
      okText: "yes",
      cancelText: "no",
      onOk: $done,
      onCancel: () => {
        reject();
      },
    };

    appService.modal("c", "cancel_bill_title", options);

    async function $done() {
      try {
        const [doc] = await axiosService.put(axiosOptions);
        appService.message("s", translateX("cancel_bill_success"), "cancelled");
        resolve(doc);
      } catch (error) {
        reject(error);
      }
    }
  });
};

export default receivableService;
